<template>
  <template v-if="isGroup">
    <div class="elv-defi-table-group-rows">
      <div class="elv-defi-table-group-rows-content">
        <template v-if="contentLogoData?.name || contentName">
          <div
            class="elv-defi-table-group-rows-content__label has-logo"
            :class="{ 'is-level-1': isGroup && props.params.node?.level === 0 }"
          >
            <img v-if="contentLogoData?.logo" :src="contentLogoData?.logo" :alt="contentLogoData?.name" />{{
              contentName
            }}
          </div>
          <div v-if="props.params.node?.level === 0" class="elv-defi-table-group-rows-content__total">
            {{ props.params.data?.childrenTotal ?? props.params.data?.list?.length }}
          </div>
        </template>
      </div>
    </div>
  </template>
  <div
    v-else-if="props.params.node?.rowPinned === 'top' && props.params.value?.cellName === 'Account/Protocol'"
    class="elv-table-top-total"
  >
    {{ t('common.total') }}
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const props: any = defineProps<{ params: any }>()
const { t } = useI18n()

const contentPaddingLeft = computed(() => {
  return props.params.node?.level < 2 ? '0px' : '9px'
})

const imageMarginRight = computed(() => {
  return props.params.value?.tableType === 'accounts' ? '6px' : '8px'
})

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 0
})

const contentLogoData = computed(() => {
  return props.params.data?.entityAccount?.platform || props.params.data?.protocol || {}
})

const contentName = computed(() => {
  return (
    props.params.data?.entityAccount?.name ||
    props.params.data?.protocol?.name ||
    props.params.data?.type ||
    props.params.data?.name
  )
})
</script>
<style lang="scss">
.elv-table-top-total {
  color: #0e0f11;
  font-family: 'Plus Jakarta Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.elv-defi-table-group-rows {
  display: flex;

  .elv-defi-table-group-rows-content {
    display: flex;
    align-items: center;
    height: 42px;
    padding-left: v-bind('contentPaddingLeft');

    img {
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50%;
    }

    p {
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-left: v-bind('imageMarginRight');
      margin-right: 4px;
    }

    span {
      color: #838d95;
      font-family: 'Barlow';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .elv-defi-table-group-rows-content__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    height: 24px;

    box-sizing: border-box;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 13px;
    line-height: 24px;
    color: $elv-color-0E1420;

    &.is-level-1 {
      padding: 0px 8px;
      background: $elv-color-FFE5BE;
      border: 1px solid $elv-color-FFD89E;
      border-radius: 2px;
    }

    &.has-logo {
      gap: 4px;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }

  .elv-defi-table-group-rows-content__total {
    font-family: 'Barlow';
    // margin-top: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: $elv-color-838d95;
    height: 24px;
    margin-left: 10px;
  }
}
</style>
