<template>
  <div class="elv-ag-table-pool">
    <template v-if="isGroup && props.params?.node.level === 2">
      <SvgIcon
        class="elv-ag-table-pool-group-arrow"
        name="events-arrow"
        width="16"
        height="16"
        fill="#838D95"
        :style="{ transform: rotate }"
        @click="onCheckGroupExpanded"
      />

      <div v-if="props.params?.data?.currencyList?.length" class="elv-ag-table-pool-content">
        <ul class="elv-ag-table-pool-currency__list">
          <img
            v-for="item in props.params?.data?.currencyList"
            :key="item.symbol"
            :src="item.logo"
            :alt="item.showSymbol"
          />
        </ul>
        <div v-if="props.params?.data?.poolShowSymbol?.length > 3" class="elv-ag-table-pool-currency__surplus">
          +{{ poolSurplusCount }}
        </div>
        <p class="elv-ag-table-pool__text">
          {{ poolText }}
        </p>
      </div>
    </template>
    <div v-if="!isGroup && props.params.node?.rowPinned !== 'top'" class="elv-ag-table-pool-currency">
      <img :src="props.params.data?.underlyingCurrency?.logo" :alt="props.params.data?.underlyingCurrency?.symbol" />
      <p>{{ props.params?.data?.underlyingCurrency?.showSymbol }}</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props: any = defineProps<{ params: any }>()

const rotate = ref('rotate(0deg)')

const isGroup = computed(() => {
  return props.params.node?.group && props.params.colDef.columnIndex === 1
})

const poolText = computed(() => {
  return props.params?.data?.poolShowSymbol?.map((item: any) => item).join('+')
})

const poolSurplusCount = computed(() => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  return props.params?.data?.poolShowSymbol.length - 3
})

const onCheckGroupExpanded = () => {
  rotate.value = props.params?.node.expanded ? 'rotate(0deg)' : 'rotate(90deg)'
  props.params?.node.setExpanded(!props.params?.node.expanded)
}

watchEffect(() => {
  rotate.value = props.params?.node.expanded ? 'rotate(90deg)' : 'rotate(0deg)'
})
</script>

<style lang="scss">
.elv-ag-table-pool {
  display: flex;
  align-items: center;
  max-width: 160px;

  .elv-ag-table-pool-group-arrow {
    overflow: inherit;
    display: flex;
    cursor: pointer;
    height: 16px !important;
    transition: transform 0.3s ease-in-out;
  }

  .elv-ag-table-pool-content {
    display: flex;
    align-items: center;
  }

  .elv-ag-table-pool-currency__list {
    display: flex;
    align-items: flex-start;
    gap: -6px;
    margin-left: 4px;
    margin-right: 4px;

    img {
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50px;
    }
  }

  .elv-ag-table-pool-currency__surplus {
    margin-right: 9px;
    display: inline-flex;
    height: 18px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0.5px solid #d0d4d9;
    background: #edf0f3;
    color: #838d95;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Barlow';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .elv-ag-table-pool__text {
    width: 79px;
    color: #0e0f11;
    font-family: 'Barlow';
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // 2行溢出显示省略号
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .elv-ag-table-pool-currency {
    display: flex;
    align-items: center;
    padding-left: 22px;

    img {
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50px;
      margin-right: 4px;
    }

    p {
      color: #0e0f11;
      font-family: 'Barlow';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
